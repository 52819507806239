frappe.provide('frappe.ui.form');

frappe.ui.form.SupplierQuickEntryForm = class SupplierQuickEntryForm extends frappe.ui.form.QuickEntryForm {
	constructor(doctype, after_insert, init_callback, doc, force) {
		super(doctype, after_insert, init_callback, doc, force);
		this.skip_redirect_on_error = true;
	}

	render_dialog() {
		this.mandatory = this.mandatory.concat(this.get_variant_fields());
		super.render_dialog();
	}

	get_variant_fields() {
		var variant_fields = [
			{
				fieldtype: "Section Break",
				label: __("Primary Contact Details"),
				collapsible: 1,
				collapsible_depends_on: "eval:doc.supplier_name==null"
			},
			{
				label: __("Email Id"),
				fieldname: "email",
				fieldtype: "Data"
			},
			{
				fieldtype: "Column Break"
			},
			{
				label: __("Mobile Number"),
				fieldname: "mobile",
				fieldtype: "Data"
			},
			{
				fieldtype: "Section Break",
				label: __("Primary Address Details"),
				collapsible: 1,
				collapsible_depends_on: "eval:doc.supplier_name==null"
			},
			{
				label: __("Address Line 1"),
				fieldname: "address_line1",
				fieldtype: "Data"
			},
			{
				label: __("Address Line 2"),
				fieldname: "address_line2",
				fieldtype: "Data"
			},
			{
				label: __("ZIP Code"),
				fieldname: "pincode",
				fieldtype: "Data"
			},
			{
				label: __("GST Category"),
				fieldname: "gst_category",
				fieldtype: "Select",
				options: [
					"Registered Regular",
					"Registered Composition",
					"Unregistered",
					"SEZ",
					"Overseas",
					"Deemed Export",
					"UIN Holders",
					"Tax Deductor"
				]
			},
			{
				fieldtype: "Column Break"
			},
			{
				label: __("City"),
				fieldname: "city",
				fieldtype: "Data"
			},
			{
				label: __("State"),
				fieldname: "state",
				fieldtype: "Data"
			},
			{
				label: __("Country"),
				fieldname: "country",
				fieldtype: "Link",
				options: "Country"
			},
			{
				label: __("GSTIN / UIN"),
				fieldname: "gstin",
				fieldtype: "Data",
				depends_on: 'eval: doc.gst_category == "Registered Regular" || doc.gst_category == "Registered Composition"'
			},
			{
				fieldtype: "Section Break",
				label: __("Bank Details"),
				collapsible: 1,
				description: "Below field are mandatory, <i>you can entirely skip for now</i> and add it to the <a href='/app/bank-account/new-bank-account-1'>Bank Account</a> doctype later"
			},
			{
				label: __("Account Name"),
				fieldname: "account_name",
				fieldtype: "Data",
				// mandatory_depends_on: "eval: doc.bank != null || doc.account_no != null || doc.branch_code != null"

			},
			{
				label: __("Bank"),
				fieldname: "bank_name",
				fieldtype: "Link",
				options: "Bank"
				// mandatory_depends_on: "eval: doc.account_name != null || doc.account_no != null || doc.branch_code != null"
			},
			{
				fieldtype: "Column Break"
			},
			{
				label: __("Account No"),
				fieldname: "account_no",
				fieldtype: "Data",
				// mandatory_depends_on: "eval: doc.account_name != null || doc.bank != null || doc.branch_code != null"
			},
			{
				label: __("Branch Code / IFSC"),
				fieldname: "branch_code",
				fieldtype: "Data",
				// mandatory_depends_on: "eval: doc.account_name != null || doc.bank != null || doc.account_no != null"
			}
		];

		return variant_fields;
	}
};
